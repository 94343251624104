import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { RoleApiClient } from "../ts/clients/roles-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./management-vloge-view.html";

@injectable()
export class ManagementVlogeView {
    private _apiClient: RoleApiClient;
    private _translationService: TranslationService;
    private itemsPerPage = 25;

    public constructor(roleApiClient: RoleApiClient, translationService: TranslationService) {
        this._apiClient = roleApiClient;
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke
            this._renderData();
            var translations = this._translationService.currentTranslations;
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: $("#main").find(".vloge-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    return await this._apiClient.getList(currentPage, this.itemsPerPage);
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);
            this.listeners();

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    public async listeners(): Promise<void> {
        const apiClient = this._apiClient;
        $(document).on('click', '[data-action=download]', async function (ev) {
            ev.preventDefault();
            var vlogaId = parseInt($(ev.currentTarget).attr("data-vloga-id") as string, 10);
            var vlogaName = $(ev.currentTarget).attr("data-vloga-name") as string;
            await apiClient.roleDownload(vlogaId)
                .then((fileContent) => {
                    var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                    var url = URL.createObjectURL(blob);

                    var fileName: string = fileContent.fileName;
                    if (!fileName) {
                        fileName = vlogaName.toLowerCase().replace(/\s+/g, '_') + "_" + moment(new Date()).format("yyyyMMDDHHmm");
                        fileName = fileName.replace(/(_)_+|(\\.)\\.+|(-)-+/, "$1$2$3") + ".xlsx";
                    }

                    var fileLink = document.createElement('a');
                    fileLink.href = url;
                    fileLink.download = fileName;
                    fileLink.click();
                })
                .catch((ex) => {
                    $('#main').text(this._translationService.currentTranslations[ex.message]);
                });
        });
        
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlEnvelope": "../img/icon-envelope.svg",
            "imageUrlEdit": "../img/icon-edit.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html); 

    }
    private dataTableModel(translations: any): TableWidgetTableMap {
        const tableMap = {
            title: translations["Roles"] ?? "Roles",
            classInsert: "vloge-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["Id"] ?? "ID",
                    value: "id"
                },
                {
                    name: translations["Name"] ?? "Name",
                    value: "name"
                },
                {
                    name: translations["internet"] ?? "Internet",
                    value: "internetAccess"
                },
                {
                    name: translations["role"] ?? "Role",
                    value: "role1"
                },
                {
                    name: translations["Count"] ?? "Count",
                    value: "users.length"
                },
                {
                    name: ` <img src="../img/icon-download.svg" class="img-fluid" />`,
                    html: ` <a href="#" data-action="download" data-vloga-id="{{id}}" data-vloga-name="{{name}}">
                                                <img src="../img/icon-document.svg" class="img-fluid td-icon" />
                                                <span>XLS</span>
                                            </a>`
                },
                {
                    name: "",
                    html: `<a href="#/management/vloge/{{id}}">
                                                <img src="../img/icon-edit.svg" class="img-fluid td-icon" />
                                            </a>`
                },

            ]
        };
        return tableMap;
    }
}