import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import { SportFitnesKarticaLogModal } from "./sport-fitness-kartica-log-modal";
import template from "./sport-upravljaj-kartice-view.html";

@injectable()
export class SportUpravljajKarticeView {
    private _apiClient: SportEventApiClient;
    private itemsPerPage = 10;
    private _translationService: TranslationService;
    private _tableWidget: TableWidget = null!;
    private _filtersForm: JQuery<HTMLElement> | null = null;
    private _searchInputElement: JQuery<HTMLElement> | null = null;
    private _selectedCards: Array<number> | null;

    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf, private sportFitnesKarticaLogModal: SportFitnesKarticaLogModal, private loader: FullscreenLoader) {
        this._apiClient = sportEventApiClient; 
        this._translationService = translationService;
        this._selectedCards = new Array<number>;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            this._renderData();
            this._selectedCards = new Array<number>;
            this._filtersForm = $('#main').find("form[name=fitness-card-filters-form]");
            this._searchInputElement = this._filtersForm.find("[name=search]");
            this._filtersForm.on("submit", (ev) => this.onFilterFormSubmit(ev));
            var tableWidgetElement = $("#main").find(".fitness-card-table-placeholder");
            var translations = this._translationService.currentTranslations;
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: tableWidgetElement,
                getData: async (currentPage) => {
                    var data = await this._apiClient.getFitnessCardsAdminLst(currentPage, this.itemsPerPage, this._searchInputElement!.val() as string);
                    data.items.forEach((value) => {
                        (value as any).status0 = value.status == 0;
                        (value as any).status1 = value.status == 1;
                        (value as any).status2 = value.status == 2;
                    })
                    return data;
                },
                onRender: () => {
                    tableWidgetElement.find("[data-action=accept]").on("click", (ev) => {
                        ev.preventDefault();
                        var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
                        this._apiClient.fitnessCardAccept(id).then(() => {
                            this.notyf.success(translations.AcceptSuccess);
                            tableWidget.refresh(tableWidget.currentPage, this._translationService.currentTranslations);
                        })
                            .catch(() => {
                                this.notyf.error(translations.AcceptError);
                            });
                    });
                    tableWidgetElement.find("[data-action=charge]").on("click", (ev) => {
                        ev.preventDefault();
                        var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
                        this._apiClient.fitnessCardCharge(id).then(() => {
                            this.notyf.success(translations.ChargeSuccess);
                            tableWidget.refresh(tableWidget.currentPage, this._translationService.currentTranslations);
                        })
                            .catch(() => {
                                this.notyf.error(translations.ChargeError);
                            });
                    });
                    tableWidgetElement.find("[data-action=reject]").on("click", (ev) => {
                        ev.preventDefault();
                        var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
                        this._apiClient.fitnessCardReject(id).then(() => {
                            this.notyf.success(translations.RejectSuccess);
                            tableWidget.refresh(tableWidget.currentPage, this._translationService.currentTranslations);
                        })
                            .catch(() => {
                                this.notyf.error(translations.RejectError);
                            });
                    });

                    tableWidgetElement.find("[data-action=open-log]").on("click", (ev) => {
                        ev.preventDefault();
                        var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
                        this.sportFitnesKarticaLogModal.show(id).then((hasChanges) => {
                            if (hasChanges) {
                                tableWidget.refresh(tableWidget.currentPage, this._translationService.currentTranslations);
                            }
                        })
                            .catch(() => {
                                this.notyf.error(translations.LoadingError);
                            });
                    });
                    $("#btn-download").unbind("click");
                    $("#btn-download").on("click", (ev) => {
                        ev.preventDefault();
                        var self = this;
                        if (this._selectedCards != null && this._selectedCards.length > 0) {
                            var downloadingNotyf = this.notyf.open({
                                type: "info",
                                message: translations.Downloading,
                                duration: 50000
                            });
                            this.loader.show();
                            this._apiClient.fitnessCardDownload(this._selectedCards)
                                .then((fileContent) => {
                                    var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                                    var url = URL.createObjectURL(blob);

                                    var fileName: string = fileContent.fileName;

                                    if (!fileName) {
                                        fileName = "Fitness_Cards_" + new Date().getDate().toString() + "_" + new Date().getMonth().toString() + "_" + new Date().getFullYear().toString();
                                    }

                                    var fileLink = document.createElement('a');
                                    fileLink.href = url;
                                    fileLink.download = fileName;
                                    fileLink.click();

                                    self._selectedCards = [];
                                    $(".number-selected").html("(0)");
                                    tableWidget.refresh(tableWidget.currentPage, this._translationService.currentTranslations);
                                })
                                .catch((ev) => {
                                    console.log(ev)
                                    this.notyf.error(translations.ErrorWhileDownloadingFile);
                                }).finally(() => {
                                    this.notyf.dismiss(downloadingNotyf);
                                    this.loader.hide();
                                })

                        } else {
                            this.notyf.error(translations.ErrorNoFilesSelected);
                        }
                    });
                    $("#btn-select-for-export").unbind("click");
                    $("#btn-select-for-export").on("click", (ev) => {
                        ev.preventDefault();
                        var self = this;
                        this.loader.show();
                        this._apiClient.getCardsForExport().then((data: any) => {
                            if (data != null && data.length > 0) {
                                for (var i = 0; i < data.length; i++) {
                                    if (self._selectedCards && self._selectedCards.includes(data[i])) {
                                        continue;
                                    }
                                    self._selectedCards?.push(data[i]);
                                }
                                $(".number-selected").html("(" + self._selectedCards?.length + ")");
                                this.notyf.success("Uporabniki izbrani");
                            }
                            else {
                                this.notyf.error("Ni uporabnikov za fizično kartico");
                            }
                            this.loader.hide();
                        })
                        .catch(() => {
                            this.notyf.error(translations.ChargeError);
                            this.loader.hide();
                        });
                    });
                    var self = this;
                    //klik na checkbox - izberi
                    $(".card-checkbox").on("click", function (ev) {
                        const target = ev.currentTarget as HTMLInputElement;
                        var dataId = target.getAttribute("data-id");
                        if (!dataId) {
                            return;
                        }
                        var id = parseInt(dataId);
                        var selected = $(target).is(":checked")
                        if (selected) {
                            //Add
                            if (self._selectedCards && self._selectedCards.includes(id)) {
                                //Element je že dodan
                                return;
                            }
                            self._selectedCards?.push(id);
                        }
                        else {
                            //remove
                            if (!self._selectedCards) {
                                return;
                            }
                            const index = self._selectedCards.indexOf(id);
                            if (index > -1) {
                                self._selectedCards.splice(index, 1);
                            }
                            var obj: HTMLInputElement = $(".select-all")[0] as HTMLInputElement;
                            obj.checked = false;
                        }     
                        $(".number-selected").html("(" + self._selectedCards?.length + ")")
                    });
                    //izberi vse na trenutni strani
                    $(".select-all").on("click", async function (ev) {
                        const target = ev.currentTarget as HTMLInputElement;
                        var selected = $(target).is(":checked")
                        if (selected) {
                            self.showLoader();
                            var x = await self._apiClient.fitnessCardsCurrentPageIds(tableWidget.currentPage, self.itemsPerPage, self._searchInputElement!.val() as string);
                            x.items.forEach(function (id: number) {
                                if (self._selectedCards && self._selectedCards.includes(id)) {
                                    //Element je že dodan                                    
                                } else {
                                    self._selectedCards?.push(id);
                                }                                
                            }); 
                            //self._selectedCards = x.items;
                            $(".number-selected").html("(" + self._selectedCards?.length + ")");
                            self.HideLoader();
                        } else {
                            var x = await self._apiClient.fitnessCardsCurrentPageIds(tableWidget.currentPage, self.itemsPerPage, self._searchInputElement!.val() as string);
                            x.items.forEach(function (id: number) {
                                if (self._selectedCards && self._selectedCards.includes(id)) {                                   
                                    const index = self._selectedCards.indexOf(id);
                                    if (index > -1) {
                                        self._selectedCards.splice(index, 1);
                                    }
                                }
                            }); 
                            //self._selectedCards = [];
                            $(".number-selected").html("(" + self._selectedCards?.length + ")");
                        }
                    });
                    //označevanje že izbranih med premikanjem po straneh
                    $('#main').on('DOMSubtreeModified', () => { 
                        var count = 0;
                        $(".card-checkbox").each((_i, obj) => {
                            const target: HTMLInputElement = obj as HTMLInputElement;
                            var dataId = target.getAttribute("data-id");
                            if (!dataId) {
                                return;
                            }
                            if (this._selectedCards == null) {
                                return;
                            }
                            var id = parseInt(dataId);  
                            if (this._selectedCards.includes(id)) {
                                target.checked = true;
                                count++;
                            } else {
                                target.checked = false;
                            }
                        })
                        if (count == self.itemsPerPage) {
                            var input: HTMLInputElement = $(".select-all")[0] as HTMLInputElement;
                            input.checked = true;
                        }
                    });
                }
            });
            this._selectedCards = new Array<number>;
            tableWidget.refresh(1, this._translationService.currentTranslations);
            this._tableWidget = tableWidget;
        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async onFilterFormSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();
        await this.search();
    }

    private async search(): Promise<void> {
        if (this._searchInputElement == null) {
            return;
        }
        await this._tableWidget.refresh(1, this._translationService.currentTranslations);

    }

    private showLoader(): void {
        $("#main").find(".loader").html(`<div class="text-center"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`);
    }

    private HideLoader(): void {
        $("#main").find(".loader").html(``);
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDelete": "../img/icon-delete.svg",
            "imageUrlTestImage": "../img/test-image-1.jpg",
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }  

    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        const tableMap: TableWidgetTableMap = {
            title: "",
            noDataContent: translations.NoData,
            hideTitle: true,
            classInsert: "table-wrapper",
            columns: [
                {
                    name: `<input class="form-check-input select-all" type="checkbox" value="" />`,
                    html: `<input class="form-check-input card-checkbox" type="checkbox" value="" data-id="{{ id }}" />`
                },
                {
                    name: translations.Id,
                    value: "id"
                },
                {
                    name: translations.User,
                    html: '<a href="#" data-action="open-log" data-id={{id}}>{{user}}</a>'
                },
                {
                    name: translations.Status,
                    html: `
{{#status0}}
<div class="status nalozeno">`+ translations.Uploaded +`</div>
{{/status0}}
{{#status1}}
<div class="status preteceno">`+ translations.Rejected +`</div>
{{/status1}}
{{#status2}}
<div class="status sprejeto">`+ translations.Accepted +`</div>
{{/status2}}
`
                },
                {
                    name: translations.Physical,
                    html: "{{#physical}}"+ translations.Yes +"{{/physical}}{{^physical}}" + translations.No + "{{/physical}}"
                },
                {
                    name: translations.Subscription,
                    html: "{{#active}}" + translations.Yes + "{{/active}}{{^active}}" + translations.No + "{{/active}}"
                },
                {
                    name: translations.Created,
                    html: "{{#formators.dateTimeFormat}}{{created}}{{/formators.dateTimeFormat}}"
                },
                {
                    name: translations.Updated,
                    html: "{{#formators.dateTimeFormat}}{{updated}}{{/formators.dateTimeFormat}}"
                },
                {
                    class: "text-center",
                    name: "&nbsp;",
                    html: `
{{^status2}}
{{^status1}}
<div class="action-buttons">
                                                <a href="#" data-action="accept" class="circle-accept" data-id="{{id}}" title="` + translations.Accept + `"></a>
                                                <a href="#" data-action="reject" class="circle-decline" data-id="{{id}}" title="` + translations.Reject + `"></a>
</div>
{{/status1}}
{{#status1}}
<div class="action-buttons"></div>
{{/status1}}
{{/status2}}
{{#status2}}
<a href="#" class="btn btn-sm btn-primary w-100" data-action="charge"  data-id="{{id}}">` + translations.Charge + ` {{price}} €</a>
{{/status2}}
                                            `
                }
            ]
        };

        return tableMap;
    }
}