import { injectable } from "inversify";
import * as Mustache from "mustache";
import { SmallNodeWidget } from "../../../skupno/src/ts/widgets/small-node-widget";
import { TranslationService } from "../ts/translation-service";
//import { NamizjeApiClient } from "../ts/clients/namizje-api-client";
import template from "./namizje-view.html";
import { DesktopApiClient } from "../ts/clients/desktop-api-client";
import { NoticeApiClient } from "../ts/clients/notice-api-client";
/*import { DamagesApiClient } from "../ts/clients/damage-api-client";*/
import { OpominModel } from "../ts/models/opomin-model";
/*import { DamagesModel } from "../ts/models/damages-model";*/
import { NotificationResponse } from "../ts/models/notification-response";
import { StatementResponse } from "../ts/models/statement-response";
import { SportEventDesktopResponse } from "../ts/models/sport-event-desktop-response";
import { SportPackageDesktopResponse } from "../ts/models/sport-package-desktop-response";
import { OvernightResponse } from "../ts/models/overnight-response";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { KuponModal } from "./kupon-modal";
import { UserApiClient } from "../ts/clients/users-api-client";
import { Notyf } from "notyf";
import { RacuniModel } from "../ts/models/racuni-model";
import { InvoiceApiClient } from "../ts/clients/invoice-api-client";


var _smallNodeWidget: SmallNodeWidget = new SmallNodeWidget();

@injectable()
export class NamizjeView {
    //private _apiClient: namizjeApiClient;
    private _apiClient: DesktopApiClient;
    private _userApiClient: UserApiClient;    
    private _noticeClient: NoticeApiClient; 
    private _invoiceClient: InvoiceApiClient; 

   /* private _damagesApiClient: DamagesApiClient*/
    private _translationService: TranslationService;
    private _translations: any;
    private _adminContacts: any;

    public constructor(desktopApiClient: DesktopApiClient,
        noticeApiClient: NoticeApiClient,
        userApiClient: UserApiClient,
        /*damagesApiClient: DamagesApiClient*/
        translationService: TranslationService,
        private currentUserAccessor: CurrentUserAccessor,
        private kuponModal: KuponModal,
        private notyf: Notyf,
        invoiceClient: InvoiceApiClient,
    ) {
        
        this._apiClient = desktopApiClient;
        this._userApiClient = userApiClient;
        this._noticeClient = noticeApiClient;
        /*this._damagesApiClient = damagesApiClient;*/
        this._translationService = translationService;  
        this._adminContacts = null;
        this._invoiceClient = invoiceClient;
    }

    public async load(): Promise<void> {
        try {


            // var user = await this.currentUserAccessor.getUser()            

            var SatatmentsData = await this._apiClient.getStatmentsData();            
            var NotifitationData = await this._apiClient.getNotificationData();
            this._adminContacts = await this._apiClient.getAdminContacts();   

            var OpomniniData: any = null       
            var invoiceData: any = null       

    
            try {
                OpomniniData = await this._noticeClient.getList(); 
                invoiceData = await this._invoiceClient.getList();

                 
            } catch (e) {

                OpomniniData = null
                invoiceData = null
            }
            
            var SportActivitiesData = await this._apiClient.getSportActivitiesData();             
            var SportPackagesData = await this._apiClient.getSportPackagesData();
            var OvernightData = await this._apiClient.getOvernightData();           

            //var CalculationOfDamageData: any = null
            //try {
            //    if (user.userName != "stanovalec") {
            //        CalculationOfDamageData = await this._apiClient.getDamageList();
            //    }

            //} catch (e) {

            //    CalculationOfDamageData = null
            //}

            //var CalculationOfDamageData = await this._apiClient.getDamageList();

            
            await this._renderData();            
            
            this._RenderStatmentSmallNode(SatatmentsData);
            this._RenderNotificationSmallNode(NotifitationData);             
                   
            this._RenderSportPackagesSmallNode(SportPackagesData);           
            this._RenderStaysSmallNode(OvernightData); 

            this._RenderOpominiSmallNode(OpomniniData != null ? OpomniniData.items : null);
            this._RenderSportActivitiesSmallNode(SportActivitiesData);     


            var sortedInvoices = invoiceData != null ? invoiceData.items.sort((a: RacuniModel, b: RacuniModel) => new Date(b.created).getTime() - new Date(a.created).getTime()) : [];
            this._RenderRacuniSmallNode(sortedInvoices);

    
            
            /*this._RenderCalculationOfDamageSmallNode(CalculationOfDamageData != null ? CalculationOfDamageData.items : null);  */

            $(".clickable").click(function (event) {
                var link = event.currentTarget.getAttribute("href")                             
                window.location.href = "./" + link 
            });
            this._apiClient.showCouponPopup().then((data) => {
                if (data && data.show) {
                    $(".kupon-text-date").html(data.valid);
                    $(".tile-kupon").css({ 'display': 'block' });
                    $(".kupon-modal-open").on('click', (ev) => {
                        ev.preventDefault();
                        this.kuponModal.show();
                    });
                    $(".kupon-close").click(function () {
                        $(".tile-kupon").css({ 'display': 'none' });
                    });
                }
            });

            this._userApiClient.checkEmailConfirmed().then((data) => {
                if (data) {
                    if (data.confirmed) {
                        return;
                    }
                    else {
                        //Show confirm email alert
                        $(".dashboard-mail-alert").removeAttr("style");
                        if (data.uiMessage) {
                            $(".dashboard-mail-alert").find(".active-campaign-text").html(data.uiMessage);
                        }

                        $(".dashboard-resend-email").on("click", (ev) => {
                            ev.preventDefault();
                            var translations = this._translationService.currentTranslations;
                            this._userApiClient.resendVerification()
                                .then(() => {
                                    this.notyf.success(translations.ResendSuccess);
                                })
                                .catch(() => {
                                    this.notyf.error(translations.ResendError);
                                });
                        });
                    }
                }
            })

        } catch (e) {

            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(): Promise<void> {

        // Build a view model from the API data
        var currentUser = await this.currentUserAccessor.getUser();
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            translations: this._translationService.currentTranslations,
            currentUser: currentUser,
            adminContacts: this._adminContacts
        } as any;      
        this._translations = this._translationService.currentTranslations;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);
        
    }

    private formatDateTime(date: Date) {          
        if (date == null)
            return "/"

        var stringDate = "";
        var dateArray = date.toString().includes("T") ? date.toString().split('T') : date.toString().split(' ')         

        var datetime = dateArray[0].split('-')
        var time = dateArray[1].split(':')

        stringDate += [datetime[2], datetime[1], datetime[0] ].join('.');
       
        if (time[0] == "00" && time[1] == "00" && time[2] == "00" )
            return stringDate;

        stringDate += " ob " + [time[0], time[1]].join(':');
        return stringDate;        
    }

    private formatDate(date: Date) { 
        if (date == null)
            return "/"

        var dateArray = date.toString().split('T')
        var datetime = dateArray[0].split('-')        

        return  [datetime[2], datetime[1], datetime[0]].join('.');
        
    }

    private _RenderStatmentSmallNode(responseData: StatementResponse): void {

        var dataArray = responseData.list

        if (dataArray == null || dataArray.length == 0) {

            //const nodeMap = {
            //    title: this._translations.Statement,
            //    tileColor: "white",
            //    tileTitle: this._translations.NoStatments,
            //    tileTitleColor: "green",
            //    mainTitleStyle: "",
            //    titleWeight: "bold",
            //    name: "Izjave",
            //    class: "tile-statments",
            //    goToLink: "#/izjave",
            //    controls: [
            //        {
            //            name: "next",
            //            value: "",
            //            controlsUrl: "#/izjave"
            //        },
            //    ],
            //};
            //_smallNodeWidget.insertTitleNodeWithText(nodeMap);
            return;
        }

        
        // Notifications
        var validToDate = dataArray[0].validTo != null ? this.formatDate(dataArray[0].validTo as Date) : "/";
        var formatedDateTime = this.formatDate(dataArray[0].validFrom) + " - " + validToDate
       
        const data = {
            // todo add status             
            valid: formatedDateTime,
            status: dataArray[0].isSigned ? "Podpisan" : "Nepodpisan" , 
            sender: "Uprava",             
            
        }

        var opis = null
        if (this._translationService.currentLanguage == "sl") 
            opis = dataArray[0].name
        else
            opis = dataArray[0].nameEn
        

        const nodeMap = {
            title: this._translations.Statement,
            tileColor: "white",
            tileTitle: opis,
            tileTitleColor: "green",
            mainTitleStyle: "",
            titleWeight: "bold",
            name: "Izjave",
            class: "tile-statments",
            goToLink:"#/izjave",
            controls: [
                {
                    name: "circle-number",
                    value: responseData.count,
                    controlsUrl: "#/izjave"
                    
                },
                {
                    name: "next",
                    value: "",
                    controlsUrl: "#/izjave"
                },
            ],
            rows: [
                {
                    name: this._translations.Valid,
                    value: "valid",
                    nameStyle: "light",                    
                },
                {
                    name: this._translations.Status,
                    value: "status",
                    nameStyle: "light",
                    valueStyle: "blue"
                },
                {                    

                    name: this._translations.Sender,
                    value: "sender",
                    nameStyle: "light",                    
                }
            ],
            data: data
        };
        
        _smallNodeWidget.insertTitleNode(nodeMap);

    }

    private _RenderOpominiSmallNode(dataArray: Array<OpominModel>): void {   
        
        if (dataArray == null || dataArray.length == 0) {

            const nodeMap = {
                title: this._translations.Notices,
                tileColor: "red",
                tileTitle: this._translations.NoNotice,
                tileTitleColor: "green",
                mainTitleStyle: "bold red",
                titleWeight: "bold",
                name: "Športna aktivnost",
                class: "tile-opomin",
                goToLink: "#/opomini",
                controls: [
                    {
                        name: "next",
                        value: "",
                        controlsUrl: "#/opomini"
                    },
                ],
            };
            _smallNodeWidget.insertTitleNodeWithText(nodeMap);
            return;
        }

        const data = {
            time: dataArray[0].datum,
            sender: dataArray[0].posiljatelj,          
            subject: dataArray[0].zadeva,  
        }

        const nodeMap = {
            title: this._translations.Notices,
            tileColor: "red",
            tileTitle: this._translations.LastNotices,
            
            mainTitleStyle: "bold red",
            titleWeight: "bold",
            name: "Obvestila",
            class: "tile-opomin",
            goToLink: "#/opomini",
            controls: [
                {
                    name: "circle-number",
                    value: dataArray.length,
                    controlsUrl: "#/opomini"
                    
                },
                {
                    name: "next",
                    value: "",
                    controlsUrl: "#/opomini"
                },
            ],
            rows: [
                {
                    name: this._translations.Date,
                    value: "time",
                    nameStyle: "light",
                    valueStyle: "white"
                },
                {
                    name: this._translations.Sender,
                    value: "sender",
                    nameStyle: "light",
                    valueStyle: "white"
                },
                {
                    name: this._translations.subject1,
                    value: "subject",
                    nameStyle: "light",
                    valueStyle: "white"
                }

            ],

            data: data
        };
        
        _smallNodeWidget.insertTitleNode(nodeMap);

    }

    private _RenderRacuniSmallNode(dataArray: Array<RacuniModel>): void {   
        
        if (dataArray == null || dataArray.length == 0) {
            const nodeMap = {
                title: this._translations.Invoices,
                tileTitle: this._translations.NoInvoices,
                tileTitleColor: "green",
                tileColor: "white",
                mainTitleStyle: "",
                titleWeight: "bold",
                name: "Športna aktivnost",
                class: "tile-racuni",
                goToLink: "#/racuni",
                controls: [
                    {
                        name: "next",
                        value: "",
                        controlsUrl:"#/racuni"
                    },
                ],
            };
            _smallNodeWidget.insertTitleNodeWithText(nodeMap);
            return;
        }

        const data = {
            time: this.formatDate(dataArray[0].created),   
            save: this._translations.LastInvoice + " " + dataArray[0].fileName,  
            id: dataArray[0].id,
            fileName: dataArray[0].fileName
        }

        const nodeMap = {
            title: this._translations.Invoices,
            tileTitle: this._translations.LastInvoice + " " + dataArray[0].fileName.split('.')[0],
            tileTitleColor: "blue",
            tileColor: "white",
            mainTitleStyle: "",
            titleWeight: "bold",
            name: "Računi",
            class: "tile-racuni",
            controls: [
                {
                    name: "circle-number",
                    value: dataArray.length,
                    controlsUrl: "#/racuni"
                    
                },
                {
                    name: "next",
                    value: "",
                    controlsUrl: "#/racuni"
                },
            ],
            rows: [
                {
                    name: this._translations.Date,
                    value: "time",
                    nameStyle: "light",
                    valueStyle: "white"
                },
                {
                    name: this._translations.Save,
                    value: "save",
                    id: "id",
                    fileName: "fileName",
                    nameStyle: "light",
                    valueStyle: "blue",
                    isLink: true
                },
                

            ],

            data: data
        };
        
        _smallNodeWidget.insertInvoiceNode(nodeMap);


            $("#invoice").on("click", (ev) => {
            ev.preventDefault();
            debugger;
            var documentId = parseInt($(ev.currentTarget).attr("data-documentid") as string, 10);
            var documentName = $(ev.currentTarget).attr("download") as string;
            var downloadingNotyf = this.notyf.open({
                type: "info",
                message: this._translations.Downloading,
                duration: 50000
            });

            this._invoiceClient.documentDownload(documentId)
            .then((fileContent) => {
                var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                var url = URL.createObjectURL(blob);

                var fileName: string = fileContent.fileName;

                if (!fileName) {
                    fileName = documentName;
                }

                var fileLink = document.createElement('a');
                fileLink.href = url;
                fileLink.download = fileName;
                fileLink.click();
                
            })
                .catch(() => {
                    this.notyf.error(this._translations.ErrorWhileDownloadingFile);
                }).finally(() => {
                    this.notyf.dismiss(downloadingNotyf);
                })
        });
    }

    private _RenderNotificationSmallNode(responseData: NotificationResponse): void {       

        var dataArray = responseData.list
        
        if (dataArray == null || dataArray.length == 0) {

            const nodeMap = {
                title: this._translations.Notifications,
                tileColor: "white",
                tileTitle: this._translations.NoNotification,
                tileTitleColor: "green",
                mainTitleStyle: "",
                titleWeight: "bold",
                name: "Obvestila",
                class: "tile-obvestila",
                goToLink: "#/obvestila",
                controls: [
                    {
                        name: "next",
                        value: "",
                        controlsUrl: "#/obvestila"
                    },
                ],
            };
            _smallNodeWidget.insertTitleNodeWithText(nodeMap);
            return;
        }

       

        const data = {
            time: this.formatDateTime(dataArray[0].created),   
            sender: dataArray[0].author,           
            subject: dataArray[0].subject, 
            // returns notification that were not read 
            notificationCount: responseData.count

        }   
       
        const nodeMap = {
            title: this._translations.Notifications,  
            tileColor: "white",            
            tileTitle: this._translations.LastNotification,
            tileTitleColor: "green",            
            mainTitleStyle: "",
            titleWeight: "bold",
            name: "Obvestila",
            class: "tile-obvestila",
            goToLink: "#/obvestila",
            controls: [                
                {
                    name: "circle-number",
                    value: data.notificationCount,
                    controlsUrl: "/#/obvestila"
                    
                },
                {
                    name: "next",
                    value: "",
                    controlsUrl: "/#/obvestila"
                },
            ],
            rows: [
                {
                    name: this._translations.Date,
                    value: "time",
                    nameStyle: "light",
                    valueStyle: "blue"
                },
                {
                    name: this._translations.Sender,
                    value: "sender",
                    nameStyle: "light",
                    valueStyle: "blue"
                },
                {
                    name: this._translations.subject1,
                    value: "subject",
                    nameStyle: "light",
                    valueStyle: "blue"
                }

            ],

            data: data
        };

        // Notifications
        _smallNodeWidget.insertTitleNode(nodeMap);
       

    }

    private _RenderSportActivitiesSmallNode(responseData: SportEventDesktopResponse ): void {       

        var dataArray = responseData.list
        
        if (dataArray == null || dataArray.length == 0) {            

            const nodeMap = {
                title: this._translations.SportActivity,   
                tileColor: "white",
                tileTitle: this._translations.NoActivity,
                tileTitleColor: "green",                
                mainTitleStyle: "",
                titleWeight: "bold",
                name: "Športna aktivnost",
                class: "tile-sportActivities", 
                goToLink: "#/sport/seznam-dogodkov",
                rows: [                    
                ],
                button: [
                    {
                        name: this._translations.Date,
                        text: this._translations.AddNew,
                        btnStyle: "btn btn-primary",
                        href: "#/sport/seznam-dogodkov",                        
                    },                   

                ],               
            };
            _smallNodeWidget.insertTitleNodeWithText(nodeMap);
            return;
        }
       
        const data = {
            time: dataArray[0].dtStart != null ? this.formatDateTime(dataArray[0].dtStart) : null,
            subject: dataArray[0].title,            
            
        }
        const nodeMap = {
            title: this._translations.SportActivity, 
            tileColor: "white",
            tileTitle: this._translations.NextActivity,
            tileTitleColor: "green",            
            mainTitleStyle: "",
            titleWeight: "bold",
            name: "Športna aktivnost",
            class: "tile-sportActivities",
            goToLink: "#/sport/seznam-dogodkov",
            controls: [
                
                {
                    name: "circle-number",
                    value: responseData.count,
                    controlsUrl: "#/sport/seznam-dogodkov"
                    
                },
                {
                    name: "next",
                    value: "",
                    controlsUrl: "#/sport/seznam-dogodkov"
                },
            ],
            rows: [
                {
                    name: this._translations.subject1,
                    value: "subject",
                    nameStyle: "light",
                    valueStyle: "blue"
                },  
                {
                    name: this._translations.Date,
                    value: "time",
                    nameStyle: "light",
                    valueStyle: "blue"
                },                            

            ],
            data: data
        };

        _smallNodeWidget.insertTitleNode(nodeMap);
    }

    private _RenderSportPackagesSmallNode(responseData: SportPackageDesktopResponse): void {

        var dataArray = responseData.list        
        if (dataArray == null || dataArray.length == 0) {
            $(".tile-sportPackages").remove();
            return;
        }  

        const data = {
            notificationCount: dataArray.length,
            veljavnost: this.formatDate(dataArray[0].valid),
            cena: dataArray[0].price != null ? dataArray[0].price + " €" : "/",
            vsebina: dataArray[0].title
        }   

        const nodeMap = {
            title: this._translations.NewSportsPackages,
            tileColor: "white",
            tileTitle: dataArray[0].title,                       
            mainTitleStyle: "",
            titleWeight: "bold",
            name: "Novi športni paketi",
            class: "tile-sportPackages",
            goToLink: "/#/sport/seznam-dogodkov",
            controls: [
                
                {
                    name: "circle-number",
                    value: responseData.count,
                    controlsUrl: "/#/sport/seznam-dogodkov"
                    
                },
                {
                    name: "next",
                    value: "",
                    controlsUrl: "/#/sport/seznam-dogodkov"
                },
            ],
            rows: [
                {
                    name: this._translations.Valid,
                    value: "veljavnost",
                    nameStyle: "light",
                    valueStyle: "blue"
                },
                {
                    name: this._translations.Price,
                    value: "cena",
                    nameStyle: "light",
                    valueStyle: "blue"
                },
                {
                    name: this._translations.Body,
                    value: "vsebina",
                    nameStyle: "light",
                    valueStyle: "blue"
                }

            ],

            data: data
        };


        var AttachClass = _smallNodeWidget.insertTitleNode(nodeMap); 


        if (dataArray[1] != null) {
            const dataSecond = {
                veljavnost: this.formatDate(dataArray[1].valid),
                cena: dataArray[1].price != null ? dataArray[1].price + " €" : "/",
                vsebina: dataArray[1].title
            }

            const nodeTagSecond = {
                tileColor: "white",
                tileTitle: dataArray[1].title,
                tileTitleColor: "green",
                titleWeight: "bold",
                data: dataSecond,
                goToLink: "/#/sport/seznam-dogodkov",
                rows: [
                    {
                        name: this._translations.Valid,
                        value: "veljavnost",
                        nameStyle: "light",
                        valueStyle: "blue"
                    },
                    {
                        name: this._translations.Price,
                        value: "cena",
                        nameStyle: "light",
                        valueStyle: "blue"
                    },
                    {
                        name: this._translations.Body,
                        value: "vsebina",
                        nameStyle: "light",
                        valueStyle: "blue"
                    }

                ],
            }

            _smallNodeWidget.insertNode(AttachClass, nodeTagSecond);
        }
    }

    private _RenderStaysSmallNode(responseData: OvernightResponse): void {

        var dataArray = responseData.list  
        
        if (dataArray == null || dataArray.length == 0) {

            const nodeMap = {
                title: this._translations.OvernightStay,
                tileColor: "white",
                tileTitle: this._translations.NoOvernights,
                tileTitleColor: "green",
                mainTitleStyle: "",
                titleWeight: "bold",
                name: "Prenočitev",
                class: "tile-stays",
                goToLink: "/#/prenocitve",
                controls: [
                    {
                        name: "add",
                        value: "",
                        controlsUrl: "#/prenocitve/prijava"
                    },
                    {
                        name: "circle-number",
                        value: responseData.count,
                        controlsUrl: "/#/prenocitve"


                    },
                    {
                        name: "next",
                        value: "",
                        controlsUrl: "#/prenocitve"
                    },
                ],
            };
            _smallNodeWidget.insertTitleNodeWithText(nodeMap);
            return;
        }

        const data = {
            time: this.formatDate(dataArray[0].created),
            lokacija: dataArray[0].location.name,
            id: dataArray[0].id,
            notificationCount: dataArray.length
        }       

        const nodeMap = {
            title: this._translations.OvernightStay,            
            tileTitle: dataArray[0].guest,
            tileTitleColor: "blue",           
            titleWeight: "bold",
            mainTitleStyle: "",
            name: "Prenočitev",
            class: "tile-stays",
            goToLink: "#/prenocitve",
            controls: [
                {
                    name: "add",
                    value: "",
                    controlsUrl: "#/prenocitve/prijava"
                },
                {
                    name: "circle-number",
                    value: responseData.count,
                    controlsUrl: "#/prenocitve"
                    

                },
                {
                    name: "next",
                    value: "",
                    controlsUrl: "#/prenocitve"
                },
            ],

            rows: [
                {
                    name: this._translations.Date,
                    value: "time",
                    nameStyle: "light",                    
                },
                {
                    name: this._translations.Location,
                    value: "lokacija",
                    nameStyle: "light",                    
                },
                {
                    name: this._translations.Id,
                    value: "id",
                    nameStyle: "light",                   
                }
            ],

            data: data
        };


        _smallNodeWidget.insertTitleNode(nodeMap);               

    }

    //private _RenderCalculationOfDamageSmallNode(dataArray: Array<DamagesModel>): void {        

    //    if (dataArray == null || dataArray.length == 0) {

    //        const nodeMap = {
    //            title: this._translations.CalculationOfDamage,
    //            tileColor: "white",
    //            tileTitle: this._translations.NoDamage,
    //            tileTitleColor: "green",
    //            mainTitleStyle: "",
    //            titleWeight: "bold",                
    //            class: "tile-calculationOfDamage",
    //            goToLink: "/#/okvare",
    //            controls: [
    //                {
    //                    name: "next",
    //                    value: "",
    //                    controlsUrl: "#/okvare"
    //                },
    //            ],
    //        };
    //        _smallNodeWidget.insertTitleNodeWithText(nodeMap);
    //        return;
    //    }
        
    //    const data = {
    //        date: this.formatDateTime(dataArray[0].datumDogodka),
    //        lokacija: dataArray[0].location.name, 
    //        sender: dataArray[0].prijaviteljOpis,            
    //    }

    //    const nodeMap = {
    //        title: this._translations.CalculationOfDamage,
    //        tileTitle: dataArray[0].kratekOpis,
    //        tileTitleColor: "blue",
    //        titleWeight: "bold",
    //        mainTitleStyle: "",          
    //        class: "tile-calculationOfDamage",
    //        goToLink: "/#/okvare",
    //        controls: [

    //            {
    //                name: "circle-number",
    //                value: dataArray.length,                    

    //            },
    //            {
    //                name: "next",
    //                value: "",
    //                controlsUrl: "/#/okvare"
    //            },
    //        ],

    //        rows: [
    //            {
    //                name: this._translations.Date,
    //                value: "date",
    //                nameStyle: "light",
    //            },
    //            {
    //                name: this._translations.Location,
    //                value: "lokacija",
    //                nameStyle: "light",
    //            },
    //            {
    //                name: this._translations.Sender,
    //                value: "sender",
    //                nameStyle: "light",
    //            }
    //        ],

    //        data: data
    //    };

    //    _smallNodeWidget.insertTitleNode(nodeMap);

    //}

    //private _RenderMaintenanceSmallNode(dataArray: Array<DamagesModel>): void {    

    
    //    if (dataArray == null || dataArray.length == 0) {
    //        const nodeMap = {
    //            title: this._translations.Maintenance,
    //            tileColor: "white",
    //            tileTitle: this._translations.NoMalfunction,
    //            tileTitleColor: "green",
    //            mainTitleStyle: "bold",
    //            titleWeight: "bold",
    //            name: "Športna aktivnost",
    //            class: "tile-maintenance",
    //            rows: [
    //                {
    //                    name: this._translations.IfMalfunction,                        
    //                    nameStyle: "light",
    //                },                   
    //            ],
    //            button: [
    //                {
    //                    name: this._translations.Date,
    //                    text: this._translations.AddDamage,
    //                    buttonDivStyle:"controls",
    //                    btnStyle: "add",
    //                    href: "/#/okvare/dodaj",
    //                },

    //            ],
    //        };
    //        _smallNodeWidget.insertTitleNodeWithAddNew(nodeMap);
    //        return;
    //    }

    //    const data = {
    //        date: dataArray[0].datumDogodka,
    //        lokacija: dataArray[0].location.name,
    //        sender: dataArray[0].prijaviteljOpis,
    //    }

    //    const nodemap = {
    //        title: this._translations.Maintenance,
    //        tileTitle: dataArray[0].kratekOpis,
    //        tileTitleColor: "blue",
    //        titleWeight: "bold",
    //        mainTitleStyle: "bold",
    //        class: "tile-maintenance",
    //        controls: [

    //            {
    //                name: "circle-number",
    //                value: dataArray.length,
    //                controlsUrl: "/#/prenocitve"

    //            },
    //            {
    //                name: "next",
    //                value: ""
    //            },
    //        ],

    //        rows: [
    //            {
    //                name: this._translations.Date,
    //                value: "date",
    //                nameStyle: "light",
    //            },
    //            {
    //                name: this._translations.Location,
    //                value: "lokacija",
    //                nameStyle: "light",
    //            },
    //            {
    //                name: this._translations.Sender,
    //                value: "sender",
    //                nameStyle: "light",
    //            }
    //        ],

    //        data: data
    //    };

    //    _smallNodeWidget.insertTitleNode(nodemap);

    //}
}