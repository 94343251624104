import { injectable } from "inversify";
import { TableWidget } from "../../../../skupno/src/ts/widgets/table-widget";
import { InvoiceApiClient } from "../../ts/clients/invoice-api-client";
import { TranslationService } from "../../ts/translation-service";
import { UrlHelper } from "../../../../skupno/src/ts/utils/url-helper";
import * as Mustache from "mustache";
import template from "./invoices.vew.html";
import { Notyf } from "notyf";
import * as DateRangePicker from 'daterangepicker';
import * as moment from "moment";


@injectable()
export class InvoiceView {
    private _apiClient: InvoiceApiClient;
    private itemsPerPage = 25;
    private _filtersForm: JQuery<HTMLElement> | null = null;
    private _filerTdateFrom: string | undefined;
    private _filerTdateTo: string | undefined ;
    private _tableWidget: TableWidget = null!;


    public constructor(ApiClient: InvoiceApiClient, private translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = ApiClient;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<any> {

        try {
              
            var translations: any = await this.translationService.currentTranslations;
            
         

            this._renderData();
            this._initDatePicker();
            this._filtersForm = $('#main').find("form[name=invoice-filters]");
            this._filtersForm.on("submit",  (ev) => {
                this.onFilterFormSubmit(ev);
            });

            var rootElement = $("#main").find(".racuni-table-placeholder");

            var tableWidget = this._tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    var data = await this._apiClient.getList( this._filerTdateFrom, this._filerTdateTo, currentPage, this.itemsPerPage);  

                     data.items = data.items.sort((a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime());
                    return data;
             
                },
                onRender: () => {
                    rootElement.find("[data-action=download-dmg]").on("click", (ev) => {
                        ev.preventDefault();
                        var documentId = parseInt($(ev.currentTarget).attr("data-documentid") as string, 10);
                        var documentName = $(ev.currentTarget).attr("download") as string;
                        var downloadingNotyf = this.notyf.open({
                            type: "info",
                            message: translations.Downloading,
                            duration: 50000
                        });

                        this._apiClient.documentDownload(documentId)
                        .then((fileContent) => {
                            var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                            var url = URL.createObjectURL(blob);

                            var fileName: string = fileContent.fileName;

                            if (!fileName) {
                                fileName = documentName;
                            }

                            var fileLink = document.createElement('a');
                            fileLink.href = url;
                            fileLink.download = fileName;
                            fileLink.click();
                            
                        })
                            .catch(() => {
                                this.notyf.error(translations.ErrorWhileDownloadingFile);
                            }).finally(() => {
                                this.notyf.dismiss(downloadingNotyf);
                            })
                    });
                }
               
            });
         
            tableWidget.refresh(1, this.translationService.currentTranslations);
            this._tableWidget = tableWidget;

                
        } catch (e) {
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async onFilterFormSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();
        await this._tableWidget.refresh(1, this.translationService.currentTranslations);
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "imageUrlCalendarSmall": "../img/icon-calendar-small.svg",
            "imageUrlLocationSmall": "../img/icon-location-small.svg",
            translations: this.translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

    }

    private dataTableModel(translations: any): any {
        const tableMap = {
            title: translations["Invoices"] ?? "Računi",
            classInsert: "racuni-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["subject"] ?? "Zadeva",
                    value: "fileName"
                },
                {
                    name: translations["NotificationDate"] ?? "Datum obvestila",
                    html: `{{#formators}}{{#dateFormat}}{{created}}{{/dateFormat}}{{/formators}}`
                },
                {
                    name: "<i class=\"bi bi-download\" style=\"-webkit-text-stroke-width: 1px;\"></i>",
                    html: ` <a href="#" data-action="download-dmg" data-documentid="{{id}}" download="{{fileName}}" title="` + translations.DownloadDocument +`">
                                <img src="../img/icon-document.svg" class="img-fluid td-icon" />
                                <span>PDF</span>
                            </a>`
                }

            ]
        };
        return tableMap;
    }

   
    

    private _initDatePicker(): void {
        new DateRangePicker($('#main').find("#dateFromTo")[0], {
            autoUpdateInput: false, 
            autoApply: true,
            locale: {
             format: moment().creationData().locale.longDateFormat("L")
            }
       });

       $('input[name="dateFromTo"]').on('apply.daterangepicker', (_ev, picker) =>{
            this._filerTdateFrom = picker.startDate.toISOString();
            this._filerTdateTo = picker.endDate.toISOString();
           $("#dateFromTo").val(picker.startDate.format("L") + " - " + picker.endDate.format("L"));
       });

    }
}