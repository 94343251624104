import { injectable } from "inversify";
import { UserApiClient } from "../ts/clients/users-api-client";
import * as Mustache from "mustache";
import template from "./management-uporabniki-view.html";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { UserListInitResponse } from "../ts/models/user-list-init-response";
import { TranslationService } from "../ts/translation-service";
import { User } from "../ts/models/user";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";


@injectable()
export class ManagementUporabnikiView {
    private _apiClient: UserApiClient;
    private _tableWidget: TableWidget = null!;
    private _filtersForm: JQuery<HTMLElement> | null = null;
    private _searchInputElement: JQuery<HTMLElement> | null = null;
    private _lokacijaIdInputElement: JQuery<HTMLElement> | null = null;

    public constructor(userApiClient: UserApiClient, private translationService: TranslationService) {
        this._apiClient = userApiClient;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            var translations = this.translationService.currentTranslations;
            // Try to get data
            await this._apiClient.getListInit(undefined, undefined, undefined, undefined)
                .then((listInit) => {
                    if (listInit != null && listInit.list != null) {
                        this.setEmailConfirmed(listInit.list.items);
                    }
                    this._renderData(listInit);
                })
                .catch((ex) => {
                    $('#main').text(translations[ex.message]);
                });;

            this._filtersForm = $('#main').find("form[name=user-filters]");
            this._searchInputElement = this._filtersForm.find("[name=search]");
            this._lokacijaIdInputElement = this._filtersForm.find("[name=lokacija-id]");
            this._filtersForm.on("submit", (ev) => this.onFilterFormSubmit(ev));

            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: $("#main").find(".users-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    var list = await this._apiClient.getList(currentPage, undefined, this._searchInputElement!.val() as string, this._lokacijaIdInputElement!.val() as string);
                    if (list != null) {
                        this.setEmailConfirmed(list.items);
                    }
                    return list;
                }
            });
            tableWidget.refresh(1, this.translationService.currentTranslations);
            this._tableWidget = tableWidget;
 
            
        } catch (e) {

            // Clear previous content on error
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }
    private async onFilterFormSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();
        await this.search();
    }

    private setEmailConfirmed(users: Array<User>) {
        if (users != null && users.length > 0) {
            for (var i = 0; i < users.length; i++) {
                if (users[i].email && users[i].emailDate && !users[i].emailValidationHash) {
                    users[i].emailConfirmed = true;
                }
                else {
                    users[i].emailConfirmed = false;
                }
            }
        }
    }

    private async search():Promise<void> {
        if (this._searchInputElement == null || this._lokacijaIdInputElement == null) {
            return;
        }
        await this._tableWidget.refresh(1, this.translationService.currentTranslations);

    }

    private _renderData(data: UserListInitResponse): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlEnvelope": "../img/icon-envelope.svg",
            "imageUrlEdit": "../img/icon-edit.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "campuses": data.campuses,
            "data": data.list,
            translations: this.translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html); 

    }

    private dataTableModel(): TableWidgetTableMap {
        var translations = this.translationService.currentTranslations;
        const tableMap: TableWidgetTableMap = {
            title: translations.Users,
            noDataContent: translations.NoData,
            hideTitle: true,
            classInsert: "table-wrapper",
            columns: [
                {
                    name: translations.Username,
                    value: "username"
                },
                {
                    name: translations.Firstname,
                    value: "firstName"
                },
                {
                    name: translations.Lastname,
                    value: "lastName"
                },
                {
                    name: translations.Email,
                    html: "{{#email}}<a href=\"mailto:{{email}}\"><img src=\"../img/icon-envelope.svg\" class= \"img-fluid td-icon\" /> {{email}}</a>{{/email}}"
                },
                {
                    name: translations.Status,
                    html: "{{#emailConfirmed}}<div class=\"data green green-check-after\" title=\"email potrjen\"></div>{{/emailConfirmed}}"
                },
                {
                    name: translations.Location,
                    value: "location.name"
                },
                {
                    name: translations.Room,
                    value: "room"
                },
                {
                    class: "text-center",
                    name: "&nbsp;",
                    html: "<a href=\"#/management/uporabniki/pregled/{{id}}\"><img src=\"../img/icon-edit.svg\" class= \"img-fluid td-icon\" /></a>"
                }
            ]
        };

        return tableMap;
    }
}